import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { ReactComponent as LogoSVG } from "../../img/logo.svg";
import "./style.css";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { format, isValid, parse } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import storage from "./firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import axios from "axios";

interface Props { }

type Inputs = {
  cpf_cnpj: string;
  nome_completo: string;
  solicitante_data_de_nascimento: string;
  solicitante_cep: string;
  solicitante_logradouro: string;
  solicitante_bairro: string;
  solicitante_cidade: string;
  solicitante_uf: string;
  solicitante_telefone: string;
  solicitante_site: string;
  solicitante_redes_sociais: string;
  solicitante_email: string;
  razao_social: string;
  solicitante_nome_fantasia: string;
  solicitante_inscricao_estadual: string;
  solicitante_inscricao_municipal: string;
  responsavel_projeto_nome: string;
  responsavel_projeto_telefone: string;
  responsavel_projeto_cpf: string;
  proposta_iniciativa: string;
  projeto_apresentacao: string;
  doacao: string;
  patrocinio: string;
  incentivo: string;
  projeto_data: string;
  projeto_local: string;
  projeto_cidade_uf: string;
  produto_ou_valor: string;
  projeto_descricao: string;
  projeto_aderencia: string;
  aderencia_outro?: string;
  doadores_envolvidos?: string;
  nomes_dos_doadores?: string;
  contrapartida?: string;
  termo_1?: string;
  termo_2?: string;
  termo_3?: string;
  oficio_anexo: FileList;
};

export interface AddressViaCep {
  cep?: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro: boolean;
}

const FormPage: React.FC<Props> = (props) => {
  const [isCpf, setisCpf] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [donation, setDonation] = useState<boolean>(false);
  const [sponsorship, setSponsorship] = useState<boolean>(false);
  const [incentive, setIncentive] = useState<boolean>(false);
  const [invalidDoc, setInvalidDoc] = useState<boolean>(false);
  const [selectedBirthDate, setSelectedBirthDate] = useState<Date | null>(null);
  const [selectedProjectDate, setSelectedProjectDate] = useState<Date | null>(
    null
  );
  const [letterFile, setLetterFile] = useState<any>();
  const [otherFile, setOtherFile] = useState<any>();

  const [openBirthDateCalendar, setopenBirthDateCalendar] =
    useState<boolean>(false);
  const [openProjectDateCalendar, setopenProjectDateCalendar] =
    useState<boolean>(false);

  const prevDay = useRef<string | null>(null);

  useEffect(() => {
    if (selectedBirthDate) {
      const currentDay = format(selectedBirthDate, "dd");
      if (prevDay.current !== null && currentDay !== prevDay.current) {
        setopenBirthDateCalendar(false);
      }
      prevDay.current = currentDay;
    }
  }, [selectedBirthDate]);

  useEffect(() => {
    setopenProjectDateCalendar(false);
  }, [selectedProjectDate]);

  const minDate: Date = new Date();
  minDate.setDate(minDate.getDate() + 45);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const attachDirectoryName = () => {
    let inputName = completeName;
    if (completeName === "") {
      inputName = companyName;
    }
    const semEspacos = inputName.replace(/\s+/g, "_");
    const semEspeciais = semEspacos
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9_]/g, "");
    return semEspeciais;
  };

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const letterURL = await handleUpload(letterFile);
    let othersURL = "await handleUpload(otherFile)";
    if (otherFile) {
      othersURL = await handleUpload(otherFile);
    }

    const emailData = {
      ...data,
      doacao: donation ? "sim" : "não",
      patrocinio: sponsorship ? "sim" : "não",
      incentivo: incentive ? "sim" : "não",
      reply_to_doacao: donation && "doacao@frisia.coop.br",
      reply_to_patrocinio: sponsorship && "patrocinio@frisia.coop.br",
      oficio_url: letterURL,
      outros_url: otherFile && othersURL,
    };
    if (donation) {
      emailjs
        .send(
          "service_sxr2fdk",
          "template_4f8fyqr",
          emailData,
          "PRAAmuEQPC6btXdlf"
        )
        .then((response) => {
          openModal();
        });
    }
    if (sponsorship || incentive) {
      emailjs
        .send(
          "service_sxr2fdk",
          "template_s15629b",
          emailData,
          "PRAAmuEQPC6btXdlf"
        )
        .then((response) => {
          openModal();
        });
    }
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => window.location.reload();

  const cpfOrCnpj = watch("cpf_cnpj") || "";
  const cep = watch("solicitante_cep") || "";
  const otherDonors = watch("doadores_envolvidos") || "";
  const gripProject = watch("projeto_aderencia") || "";
  const completeName = watch("nome_completo") || "";
  const companyName = watch("razao_social") || "";

  useEffect(() => {
    if (cpfOrCnpj.length === 14) {
      setisCpf("cpf");
    } else if (cpfOrCnpj.length > 14) {
      setisCpf("cnpj");
    }
  }, [cpfOrCnpj]);

  const formatCpfOrCnpj = (value: string) => {
    const numericValue = value.replace(/\D/g, "");
    if (numericValue.length <= 11) {
      if (!isCPFValid(value)) {
        setInvalidDoc(true);
      } else {
        setInvalidDoc(false);
      }
      return numericValue.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
    } else {
      if (!isCNPJValid(value)) {
        setInvalidDoc(true);
      } else {
        setInvalidDoc(false);
      }
      return numericValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
    }
  };

  const handleChangeFile = (
    event: ChangeEvent<HTMLInputElement>,
    setFile: (file: File) => void
  ) => {
    if (event.target.files) {
      console.log(event?.target.files[0]);
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async (file: File): Promise<string> => {
    const directoryName = attachDirectoryName();
    if (!directoryName) {
      throw new Error("Directory name is not available");
    }

    const storageRef = ref(storage, `${directoryName}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise<string>(async (resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (err) => {
          console.error("Upload error:", err);
          reject(err);
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("Download URL:", url);
            resolve(url);
          } catch (error) {
            console.error("Error getting download URL:", error);
            reject(error);
          }
        }
      );
    });
  };

  useEffect(() => {
    if (cep.length === 8) {
      getCepData(cep);
    }
  }, [cep]);

  const isCPFValid = (cpf: string): boolean => {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, "");

    // Verifica se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
      return false;
    }

    // Verifica dígitos repetidos (caso contrário, passaria na validação numérica)
    if (/(\d)\1{10}/.test(cpf)) {
      return false;
    }

    // Algoritmo de validação do CPF
    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf[i - 1]) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf[9])) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf[i - 1]) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf[10])) {
      return false;
    }

    return true;
  };

  const isCNPJValid = (cnpj: string): boolean => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/\D/g, "");

    // Verifica se o CNPJ tem 14 dígitos
    if (cnpj.length !== 14) {
      return false;
    }

    // Algoritmo de validação do CNPJ
    const weights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const sum = cnpj.split("").reduce((acc, digit, index) => {
      const weight = weights[index];
      return acc + parseInt(digit) * weight;
    }, 0);

    const remainder = sum % 11;
    const validDigit = remainder < 2 ? 0 : 11 - remainder;

    if (parseInt(cnpj[12]) !== validDigit) {
      return false;
    }

    const secondSum = cnpj.split("").reduce((acc, digit, index) => {
      const weight = weights[index + 1];
      return acc + parseInt(digit) * weight;
    }, 0);

    const secondRemainder = secondSum % 11;
    const secondValidDigit = secondRemainder < 2 ? 0 : 11 - secondRemainder;

    return parseInt(cnpj[13]) === secondValidDigit;
  };

  const getCepData = (cep: string) => {
    cep = cep.replace(/\D/g, "");

    axios
      .get<AddressViaCep>(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        if (res.data.cep) {
          setValue("solicitante_bairro", res.data.bairro);
          setValue("solicitante_logradouro", res.data.logradouro);
          setValue("solicitante_cidade", res.data.localidade);
          setValue("solicitante_uf", res.data.uf);
        }
      });
  };

  return (
    <>
      <div className="formContainer">
        <div className="introducao">
          <div className="title">
            <h1>Formulário para solicitação de Doação ou Patrocínio</h1>
            <a href="https://www.frisia.coop.br/">
              <LogoSVG />
            </a>
          </div>
          <div id="blue-line"></div>
          <div id="blue-line2"></div>
          <p>
            A Frísia acredita que as doações e patrocínios exercem papel relevante em suas
            estratégias de
            <b>
              {" "}compartilhar valor com a sociedade,{" "}
            </b>
            contribuem para o
            fortalecimento de sua rede de relacionamentos, favorecem o reconhecimento da
            marca e ajudam a
            <b>
              {" "}transmitir seus valores e princípios,{" "}
            </b>
            notadamente
            <b>
              {" "}5º e o 7º princípio do cooperativismo,{" "}
            </b>
            de “Educação, Formação e Informação” e “Interesse pela Comunidade”.
          </p>
          <p>
            Com isso, os pedidos devem cumprir critérios para serem analisados:
          </p>
          <p className="margin">
            <b>
              • Prazo:
            </b>
            {" "}mínimo de 30 dias de antecedência do evento;
          </p>
          <p className="margin">
            <b>
              • Abrangência:
            </b>
            {" "}deve ser aplicado na área de abrangência geográfica das operações da Frísia;
          </p>
          <p className="margin">
            <b>
              • Área:
            </b>
            {" "}os projetos deverão atender prioritariamente as áreas da educação, cooperativismo, cidadania e alimentação, podendo ainda contemplar iniciativas sociais, ambientais, culturais, esportivas, de apoio à inovação na cadeia produtiva do agronegócio, geração de trabalho e renda. Os projetos de Entidades Religiosas poderão ser contemplados, desde que o público-alvo não seja restrito aos fiéis;
          </p>
          <p>
            Se a sua iniciativa tem este objetivo, por favor, preencha o{" "}
            <b>
              Formulário de Pedido de Doação ou Patrocínio
            </b>
            {" "}abaixo, que iremos submeter sua solicitação ao nosso{" "}
            <b>
              Grupo de Avaliação de Projetos.{" "}
            </b>
            As análises dos formulários recebidos são realizadas em ciclos mensais.
          </p>
          <p>
            <b>IMPORTANTE:</b> a Frísia não patrocina pessoa física, exceto
            quando relacionado a cooperados ou colaboradores; eventos com apelo
            político partidário; eventos que promovam qualquer tipo de
            discriminação social; eventos exclusivamente festivos; atividades
            que não contribuam com mudanças positivas na sociedade nos aspectos
            sociais, ambientais e culturais; ou que tenham como proponente
            qualquer pessoa física ou jurídica com condutas incompatíveis com o
            Código de Integridade da cooperativa.
          </p>
        </div>
        <form
          name="form"
          method="post"
          action="enviar.php"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset className="dados-do-solicitante">
            <div className="section">
              <h2>DADOS DO SOLICITANTE*</h2>
              <h3>Essa solicitação é proposta por *</h3>
              <div className="proposta-iniciativa">
                <div className="tipo">
                  <div>
                    <Controller
                      name="proposta_iniciativa"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <input
                            type="radio"
                            {...field}
                            value="Associação"
                            id="associacao"
                          />
                          <label htmlFor="associacao">Associação</label>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="proposta_iniciativa"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <input
                            type="radio"
                            {...field}
                            value="Cooperativa"
                            id="cooperativa"
                          />
                          <label htmlFor="cooperativa">Cooperativa</label>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="proposta_iniciativa"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <input
                            required
                            type="radio"
                            {...field}
                            value="Iniciativa Privada"
                            id="iniciativa_privada"
                          />
                          <label htmlFor="iniciativa_privada">
                            Iniciativa Privada
                          </label>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="proposta_iniciativa"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <input
                            type="radio"
                            {...field}
                            value="Organização Social"
                            id="oscip_ou_organizacao_social"
                          />
                          <label htmlFor="oscip_ou_organizacao_social">
                            Organização Social
                          </label>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="proposta_iniciativa"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <input
                            type="radio"
                            {...field}
                            value="Órgão Público"
                            id="orgao_publico"
                          />
                          <label htmlFor="orgao_publico">Órgão Público</label>
                        </>
                      )}
                    />
                  </div>
                  
                  {errors.proposta_iniciativa && (
                    <span className="error-message">
                      Escolha uma das opções acima
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="section">
              <h3>Tipo do solicitante *</h3>
              <div className="inputs">
                {invalidDoc && (
                  <span className="error-message">
                    Numero de documento inválido
                  </span>
                )}

                <div className="input entirerow">
                  <Controller
                    name="cpf_cnpj"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        required
                        {...field}
                        type="text"
                        placeholder="CNPJ/CPF *"
                        value={cpfOrCnpj}
                        maxLength={16}
                        onChange={(e) => {
                          const formattedValue = formatCpfOrCnpj(
                            e.target.value
                          );
                          setValue("cpf_cnpj", formattedValue);
                        }}
                      />
                    )}
                  />
                </div>

                {isCpf === "cpf" && (
                  <>
                    <div className="input">
                      <input
                        {...register("nome_completo", {
                          required: true,
                        })}
                        required
                        type="text"
                        placeholder="Nome Completo *"
                      />
                    </div>
                    <div className="input">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {openBirthDateCalendar && (
                          <DateCalendar
                            value={selectedBirthDate}
                            onChange={(newValue) => {
                              setSelectedBirthDate(newValue);
                              if (newValue) {
                                const formatedDate = format(
                                  newValue,
                                  "dd/MM/yyyy"
                                );
                                setValue(
                                  "solicitante_data_de_nascimento",
                                  formatedDate
                                );
                              }
                            }}
                          />
                        )}
                      </LocalizationProvider>
                      <input
                        {...register("solicitante_data_de_nascimento")}
                        placeholder="Data de Nascimento *"
                        required
                        type="text"
                        onClick={() => setopenBirthDateCalendar(true)}
                      />
                      {errors.solicitante_data_de_nascimento && (
                        <div className="error-message">
                          {errors.solicitante_data_de_nascimento.message}
                        </div>
                      )}
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_cep")}
                        type="text"
                        placeholder="CEP *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_logradouro")}
                        type="text"
                        placeholder="Logradouro *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_bairro")}
                        type="text"
                        placeholder="Bairro *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_cidade")}
                        type="text"
                        placeholder="Cidade *"
                        required
                      />
                    </div>
                    <div className="input">
                      <select
                        {...register("solicitante_uf")}
                        required
                        defaultValue="Estado *"
                      >
                        <option value="">Estado *</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </select>
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_telefone")}
                        type="tel"
                        className="telefone"
                        placeholder="Telefone *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_site")}
                        type="text"
                        placeholder="Site"
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_redes_sociais")}
                        type="text"
                        placeholder="Redes Sociais"
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_email")}
                        type="email"
                        className="telefone"
                        placeholder="E-mail *"
                        required
                      />
                    </div>
                  </>
                )}
                {isCpf === "cnpj" && (
                  <>
                    <div className="input">
                      <input
                        {...register("razao_social")}
                        type="text"
                        placeholder="Razão social *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_nome_fantasia")}
                        type="text"
                        placeholder="Nome fantasia *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_inscricao_estadual")}
                        type="text"
                        placeholder="Inscrição estadual"
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_inscricao_municipal")}
                        type="text"
                        placeholder="Inscrição municipal"
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_cep")}
                        type="text"
                        placeholder="CEP *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_logradouro")}
                        type="text"
                        placeholder="Logradouro *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_bairro")}
                        type="text"
                        placeholder="Bairro *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_cidade")}
                        type="text"
                        placeholder="Cidade *"
                        required
                      />
                    </div>
                    <div className="input">
                      <select
                        {...register("solicitante_uf")}
                        required
                        defaultValue="Estado *"
                      >
                        <option value="">Estado *</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                      </select>
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_telefone")}
                        type="tel"
                        className="telefone"
                        placeholder="Telefone *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_email")}
                        type="email"
                        className="telefone"
                        placeholder="E-mail *"
                        required
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_site")}
                        type="text"
                        placeholder="Site"
                      />
                    </div>
                    <div className="input">
                      <input
                        {...register("solicitante_redes_sociais")}
                        type="text"
                        placeholder="Redes Sociais"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <fieldset className="apresentacao section">
              <h3>Apresentação sucinta do solicitante *</h3>
              <p>
                Faça uma breve apresentação, abordando seus objetivos e
                principais atuações.
              </p>
              <div className="input">
                <textarea
                  {...register("projeto_apresentacao")}
                  required
                ></textarea>
              </div>
            </fieldset>
            <fieldset className="nome-do-projeto">
              <h2>DADOS DO PROJETO</h2>
              <p className="error-message">
                A data de realização deve ser 45 dias posterior a data de hoje
              </p>
              <div className="inputs">
                <div className="input">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {openProjectDateCalendar && (
                      <DateCalendar
                        minDate={minDate}
                        value={selectedProjectDate}
                        onChange={(newValue) => {
                          setSelectedProjectDate(newValue);
                          if (newValue) {
                            const formatedDate = format(newValue, "dd/MM/yyyy");
                            setValue("projeto_data", formatedDate);
                          }
                        }}
                      />
                    )}
                  </LocalizationProvider>
                  <input
                    onFocus={() => setopenProjectDateCalendar(true)}
                    required
                    type="text"
                    {...register("projeto_data")}
                    placeholder="Data da realização *"
                  />
                  {errors.projeto_data && (
                    <div className="error-message">
                      {errors.projeto_data.message}
                    </div>
                  )}
                </div>
                <div className="input">
                  <input
                    {...register("projeto_local")}
                    required
                    type="text"
                    placeholder="Local de realização/Abrangência *"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset className="apresentacao-do-projeto tema-do-projeto section">
              <h3>Apresentação sucinta do projeto *</h3>
              <p>
                A apresentação deve conter as informações abaixo para o
                entendimento claro do projeto:
              </p>
              <ul>
                <li>a) O que é</li>
                <li>b) Objetivo</li>
                <li>c) Público-alvo</li>
                <li>d) Público esperado</li>
                <li>e) Relação do projeto com a atuação da Frísia</li>
                <li>f) Impacto na sociedade ou meio ambiente</li>
              </ul>
              <p></p>
              <div className="input">
                <textarea
                  required
                  {...register("projeto_descricao")}
                ></textarea>
              </div>
            </fieldset>
            <fieldset className="tema-do-projeto section">
              <h3>Área de aderência*</h3>
              {errors.projeto_aderencia && (
                <span className="error-message">
                  Escolha uma das opções abaixo
                </span>
              )}
              <div className="opcoes">
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Assistência social"
                    id="social"
                  />
                  <label htmlFor="social">Assistência social</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Cultura"
                    id="cultura"
                  />
                  <label htmlFor="cultura">Cultura</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Educação"
                    id="educacao"
                  />
                  <label htmlFor="educacao">Educação</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Esporte"
                    id="esporte"
                  />
                  <label htmlFor="esporte">Esporte</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Lazer"
                    id="lazer"
                  />
                  <label htmlFor="lazer">Lazer</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Meio ambiente"
                    id="ambiente"
                  />
                  <label htmlFor="ambiente">Meio ambiente</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Saúde"
                    id="saude"
                  />
                  <label htmlFor="saude">Saúde</label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("projeto_aderencia", { required: true })}
                    value="Outro"
                    id="outro"
                  />
                  <label htmlFor="outro">
                    <input
                      {...register("aderencia_outro", {
                        required: gripProject === "Outro",
                      })}
                      type="text"
                      placeholder="Outro"
                    />
                  </label>
                </div>
              </div>
            </fieldset>

            {errors.doacao && (
              <span className="error-message">
                Escolha uma das opções abaixo
              </span>
            )}
            <div className="proposta-iniciativa section">
              <h3>Tipo da solicitação*</h3>
              <p className="error-message">
                {
                  "Para Patrocínio é necessário também realizar cadastro na Frísia pelo link "
                }
                <a href="https://www.frisia.coop.br/cadastrar/cliente"  target="_blank">
                  www.frisia.coop.br/cadastrar/cliente
                </a>
              </p>
              <div className="tipo">
                <div>
                  <input
                    type="radio"
                    {...register("doacao")}
                    value="sim"
                    id="doacao"
                    onClick={() => {
                      setDonation(!donation);
                    }}
                  />
                  <label htmlFor="doacao">
                    Doação (Doações de bens materiais, entrega ou transferência
                    gratuita a terceiros){" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("doacao")}
                    value="sim"
                    id="patrocinio"
                    onClick={() => {
                      setSponsorship(!sponsorship);
                    }}
                  />
                  <label htmlFor="patrocinio">
                    Patrocínio (Ferramenta de financiamento em troca de
                    contrapartida de visibilidade da marca){" "}
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    {...register("doacao")}
                    value="sim"
                    id="incentivo"
                    onClick={() => {
                      setIncentive(!incentive);
                    }}
                  />
                  <label htmlFor="incentivo">
                    Incentivo Fiscal (Doações para projetos sociais e culturais a partir do IRPJ){" "}
                  </label>
                </div>
              </div>
            </div>

            <div className="proposta-iniciativa section">
              <h3>Produto (com quantidade) ou valor solicitado *</h3>
              <div className="input">
                <input
                  {...register("produto_ou_valor")}
                  required
                  type="text"
                  placeholder="Insira aqui sua resposta *"
                />
              </div>
            </div>

            <div className="section">
              <h3>Outros doadores envolvidos?</h3>
              {errors.doadores_envolvidos && (
                <span className="error-message">
                  Escolha uma das opções abaixo
                </span>
              )}
              <div className="proposta-iniciativa">
                <div className="tipo">
                  <div>
                    <Controller
                      name="doadores_envolvidos"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <input
                            required
                            type="radio"
                            {...field}
                            value="Sim"
                            id="sim_doadores"
                          />
                          <label htmlFor="sim_doadores">Sim</label>
                        </>
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="doadores_envolvidos"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <input
                            type="radio"
                            {...field}
                            value="Não"
                            id="nao_doadores"
                          />
                          <label htmlFor="nao_doadores">Não</label>
                        </>
                      )}
                    />
                  </div>
                </div>
                {otherDonors === "Sim" && (
                  <>
                    {errors.nomes_dos_doadores && (
                      <span className="error-message">
                        Se sim descreva quem são os outros doadores.
                      </span>
                    )}
                    <p>Descrever abaixo:</p>
                    <div className="input">
                      <input
                        {...register("nomes_dos_doadores", { required: true })}
                        type="text"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <fieldset className="contrapartida apresentacao">
              <h2>CONTRAPARTIDA *</h2>
              <p>
                Descrever apenas os itens em que haverá exposição da marca
                Frísia Cooperativa Agroindustrial
              </p>
              <div className="input">
                <textarea {...register("contrapartida")} required></textarea>
              </div>

              <div className="mensagem">
                {(errors.termo_1 || errors.termo_2 || errors.termo_3) && (
                  <span className="error-message">Marque as opções abaixo</span>
                )}
              </div>
              <div className="declaracoes">
                <div className="input">
                  <input
                    type="checkbox"
                    {...register("termo_1", { required: true })}
                    id="term1"
                    value="Sim"
                  />
                  <label htmlFor="term1">
                    Declaro que as mesmas contrapartidas, aqui propostas, não
                    serão acordadas com outras organizações que tenham missão,
                    objetivos ou atuação de mercado, correlatos aos da Frísia
                    Cooperativa Agroindustrial.
                  </label>
                </div>
                <div className="input">
                  <input
                    type="checkbox"
                    value="Sim"
                    id="term2"
                    {...register("termo_2", { required: true })}
                  />
                  <label htmlFor="term2">
                    Declaro que estou de acordo com as condições da Política de
                    Patrocínio e Doações da Frísia Cooperativa Agroindustrial
                    expostas nesse formulário, bem como com o fornecimento das
                    informações aqui solicitadas.
                  </label>
                </div>
              </div>
              <div className="tema-do-projeto">
                <h2>ANEXOS</h2>
                <p className="anexos oficio">
                  Inserir Ofício * (anexo obrigatório):
                </p>
                {errors.oficio_anexo && (
                  <span className="error-message">
                    {"O Envio de um Ofício é obrigatorio: "}
                  </span>
                )}
                <label
                  style={errors.oficio_anexo && { borderColor: "red" }}
                  className="escolher-arquivo"
                  htmlFor="arquivo-uploader"
                >
                  {letterFile?.name ? letterFile?.name : "Escolher arquivo"}
                </label>
                <input
                  type="file"
                  accept=".pdf,.png,.jpeg"
                  placeholder="Escolher arquivo"
                  id="arquivo-uploader"
                  {...register("oficio_anexo", {
                    required: "É necessário enviar um arquivo",
                  })}
                  onChange={(e) => handleChangeFile(e, setLetterFile)}
                />
                <p className="anexos">Outro anexo:</p>
                <label className="escolher-arquivo" htmlFor="arquivo-uploader2">
                  {otherFile?.name ? otherFile?.name : "Escolher arquivo"}
                </label>
                <input
                  type="file"
                  accept=".pdf,.png,.jpeg"
                  placeholder="Escolher arquivo"
                  name="oficio_anexo"
                  id="arquivo-uploader2"
                  onChange={(e) => handleChangeFile(e, setOtherFile)}
                />
              </div>
            </fieldset>
          </fieldset>
          <button className="enviar">ENVIAR FORMULÁRIO</button>
        </form>
      </div>
      {showModal && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <LogoSVG />
            <div className="modal-message">
              Agradecemos o envio da sua solicitação! Você receberá retorno da
              nossa equipe no e-mail informado no cadastro, no prazo de 5 dias
              úteis.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormPage;
